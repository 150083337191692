<template>
  <div class="embed-editor">
    <h3>Embed Management</h3>
    <div class="embed-fields">
      <div class="info">
        <diyobo-input
          type="text"
          label="Name"
          placeholder="Home Page Embed"
          required
          :val="name"
          :error="errors[0].error"
          v-model="name"
        />
      </div>
      <div class="colors">
        <color-picker
          name="text"
          display="Text"
          :color="colors.text"
          v-on:change="onColorChange('text', arguments[0])"
        />
        <color-picker
          name="charge"
          display="Charge"
          :color="colors.charge"
          v-on:change="onColorChange('charge', arguments[0])"
        />
        <color-picker
          name="price"
          display="Ticket Price"
          :color="colors.price"
          v-on:change="onColorChange('price', arguments[0])"
        />
        <color-picker
          name="buttons"
          display="Buttons"
          :color="colors.buttons"
          v-on:change="onColorChange('buttons', arguments[0])"
        />
      </div>
      <div class="style">
        <div class="input-wrapper">
          <diyobo-input
            type="dropdown"
            placeholder="Select a Font"
            label="Font"
            required
            :options="fontOptions"
            :bus="bus"
            v-on:input="onStyleChange('font', arguments[0])"
          />
        </div>
        <radio-group
          label="Border Style"
          required
          :buttons="borderButtons"
          v-on:change="onStyleChange('border', arguments[0])"
        />
        <radio-group
          label="Corner Style"
          required
          :buttons="cornerButtons"
          v-on:change="onStyleChange('corner', arguments[0])"
        />
      </div>
      <diyobo-button
        type="primary"
        :txt="editing ? 'Save Changes' : 'Create Embed'"
        v-on:click="submit"
      />
    </div>
    <embed-preview :styles="style" :colors="colors" />
  </div>
</template>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import ColorPicker from "@/components/ColorPicker.vue";
import RadioGroup from "@/components/RadioGroup.vue";
import EmbedPreview from "../components/EmbedPreview.vue";

export default {
  name: "embed-editor",
  components: {
    DiyoboInput,
    DiyoboButton,
    ColorPicker,
    RadioGroup,
    EmbedPreview
  },
  props: {
    event: String
  },
  data() {
    return {
      borderButtons: [
        { id: "check-border", name: "border", label: "Border", checked: true },
        { id: "check-no-border", name: "no-border", label: "No Border" }
      ],
      cornerButtons: [
        {
          id: "check-rounded-corners",
          name: "rounded-corners",
          label: "Rounded Corners",
          checked: true
        },
        {
          id: "check-sharp-corners",
          name: "sharp-corners",
          label: "Sharp Corners"
        }
      ],
      fontOptions: [{ value: "Comic Sans MS", label: "Comic Sans MS" }],
      errors: [
        { name: "Name", error: false },
        { name: "Font", error: false }
      ],
      bus: new Vue(),
      name: "",
      colors: {
        text: "#222",
        charge: "#222",
        price: "#222",
        buttons: "#222"
      },
      style: {
        font: "",
        border: "border",
        corner: "rounded-corners"
      },
      editing: false,
      embedId: null
    };
  },
  methods: {
    onColorChange(field, color) {
      this.colors[field] = color;
    },
    onStyleChange(field, value) {
      if (field === "border") {
        this.borderButtons.forEach(b => (b.checked = b.name === value));
      } else if (field === "corner") {
        this.cornerButtons.forEach(b => (b.checked = b.name === value));
      }

      this.style[field] = value;
    },
    validate() {
      this.errors[0].error = !this.name;
      this.errors[1].error = !this.style.font;

      return !this.errors.some(e => e.error);
    },
    submit() {
      if (!this.validate()) return;

      this.$loader.start();
      if (this.editing) {
        this.$axios
          .post("/embeds/edit", {
            id: this.embedId,
            name: this.name,
            colors: this.colors,
            style: this.style
          })
          .then(response => {
            this.editing = false;
            this.$emit("edit", {
              _id: { $oid: this.embedId },
              name: this.name,
              colors: this.colors,
              style: this.style
            });

            this.name = "";
            this.$loader.stop();
          });
      } else {
        this.$axios
          .post("/embeds/create", {
            event: this.event,
            name: this.name,
            colors: this.colors,
            style: this.style
          })
          .then(response => {
            const data = response.data;

            this.$emit("create", {
              _id: { $oid: data.id },
              name: this.name,
              colors: this.colors,
              style: this.style
            });

            this.name = "";
            this.$loader.stop();
          });
      }
    }
  },
  mounted() {
    this.onEditEmbed = embed => {
      this.editing = true;
      this.embedId = embed._id;
      this.name = embed.name;

      Object.keys(embed.colors).forEach(c => {
        this.onColorChange(c, embed.colors[c]);
      });

      Object.keys(embed.style).forEach(s => {
        this.onStyleChange(s, embed.style[s]);
      });

      this.bus.$emit("set-value-placeholder", {
        value: this.style.font,
        placeholder: this.style.font
      });
    };

    this.$bus.$on("edit-embed", this.onEditEmbed);
  },
  destroyed() {
    this.$bus.$off("edit-embed", this.onEditEmbed);
  }
};
</script>

<style lang="less" scoped>
.embed-editor {
  display: flex;
  flex-wrap: wrap;

  .embed-fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    border: 2px solid @colors[border];
    border-radius: 8px;

    .info {
      width: 100%;
    }

    .colors {
      display: flex;
      width: 100%;
      margin-bottom: 30px;

      .color-picker-wrapper {
        margin-right: 20px;
      }
    }

    .style {
      display: flex;
      width: 100%;

      .input-wrapper {
        width: 300px;
        margin-right: 40px;
      }

      .radio-group {
        margin-right: 20px;
      }
    }
  }
}
</style>