<template>
  <div class="embed-item">
    <div class="left">{{ embed.name }}</div>
    <div class="right">
      <diyobo-button type="primary" txt="Edit" v-on:click="edit" />
      <diyobo-button type="primary" txt="View" v-on:click="view" />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  name: "embed-item",
  components: {
    DiyoboButton
  },
  props: {
    embed: Object
  },
  methods: {
    edit() {
      this.$bus.$emit("edit-embed", this.embed);
    },
    view() {
      this.$emit("view-embed", this.embed);
    }
  }
};
</script>

<style lang="less" scoped>
.embed-item {
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  border: solid 2px @colors[border];
  border-radius: 8px;
}
</style>