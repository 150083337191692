<template>
  <modal
    width="800px"
    :title="embed.name + ' Preview'"
    :active="active"
    :footer="false"
    v-on:close="close"
  >
    <iframe
      width="100%"
      height="400px"
      style="border: 2px solid #444; border-radius: 8px;"
      :src="url"
    ></iframe>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "view-embed-modal",
  components: {
    Modal
  },
  data() {
    return {
      active: false,
      embed: {}
    };
  },
  computed: {
    url() {
      return (
        (process.env.NODE_ENV === "development"
          ? "http://localhost:8080/embed/"
          : "https://embed.incredevent.com/") + this.embed._id
      );
    }
  },
  methods: {
    open(embed) {
      this.embed = embed;
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      this.embed = {};

      setTimeout(() => {
        document.body.style.position = "relative";
      }, 500);
    }
  }
};
</script>