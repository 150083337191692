<template>
  <div class="embed-instructions">
    <h3>Embeds?</h3>
    <p
      class="b1"
    >Embeds allow you to add another site into your own site. We provide an embed for your events that let you show your ticket tiers in your own site. Select the method below that applies to you and follow the steps provided to add an embed to your site. This is why I'm not a writer.</p>
    <div class="instructions">
      <div class="buttons">
        <div
          v-for="(button, index) in buttons"
          class="button-outer"
          :key="index"
          :class="{ active: index === selected }"
          v-on:click="selected = index"
        >
          <div class="button">
            <img :src="button.img" :alt="button.alt" />
          </div>
        </div>
      </div>
      <div class="instructions-text">
        <ol>
          <li v-for="(step, index) in buttons[selected].steps" :key="index">{{ step }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "embed-instructions",
  data() {
    return {
      buttons: [
        {
          img: "/img/logos/custom.png",
          alt: "custom code",
          steps: [
            "be an ecommerce platform and calculate taxes wrong",
            "hire blake and britt from totey digital solutions"
          ]
        },
        {
          img: "/img/logos/wix.png",
          alt: "wix",
          steps: [
            "use squarespace because they sponsor linus tech tips",
            "https://support.wix.com/en/article/wix-editor-using-iframes-to-display-visible-content-on-your-site#embedding-an-external-site"
          ]
        },
        {
          img: "/img/logos/squarespace.png",
          alt: "squarespace",
          steps: [
            "use wix because linus sebastian of linus tech tips is a sellout",
            "https://support.squarespace.com/hc/en-us/articles/206543617-Embed-Blocks"
          ]
        },
        {
          img: "/img/logos/wordpress.png",
          alt: "wordpress",
          steps: [
            "use magento its more powerful",
            "https://kinsta.com/blog/wordpress-iframe/"
          ]
        }
      ],
      selected: 0
    };
  }
};
</script>

<style lang="less" scoped>
.embed-instructions {
  .instructions {
    margin-top: 40px;

    .buttons {
      display: flex;
      justify-content: space-between;

      .button-outer {
        padding: 2px;
        border-radius: 8px;
        cursor: pointer;

        &.active {
          background: @colors[primary-gradient];
        }

        .button {
          display: flex;
          width: 200px;
          height: 100px;
          padding: 0 20px;
          background: @colors[content-background-dark];
          border-radius: 8px;
          border-bottom: 1px solid #3b3b3b;
          box-shadow: inset 0 2px 1px 2px @colors[content-shadow];

          img {
            display: block;
            width: 100%;
            margin: auto;
          }
        }
      }
    }

    .instructions-text {
      margin-top: 40px;
      padding: 10px 0 15px 0;
      background: @colors[content-background-dark];
      border-radius: 8px;
      border-bottom: 1px solid #3b3b3b;
      box-shadow: inset 0 2px 1px 2px @colors[content-shadow];
    }
  }
}
</style>