<template>
  <div class="covid-cont">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="row text-wrap">
          <h2>INCREDEVENT COVID Relief Program</h2>
          <h2 class="second-header">Why we want to help?</h2>
          <div class="covid-text">
            At INCREDEVENT, we understand that COVID-19 and the resulting
            response absolutely gutted the events industry. We know, we're in
            the industry with you. We also know that your ticket buyers will be
            price sensitive for quite some time.
          </div>
          <div class="covid-text">
            In an effort to help ease that burden, we've developed a COVID-19
            Relief Program (CRP). Our CRP is accepting applications until Dec
            31, 2022. If accepted into the program you will receive $1.00 T-Fees
            for 12 months (this works out to around $1.00). T-Fees usually range
            from $1.30 - $6.30. Processing fees are extra for taking Credit
            Cards and PayPal, and we charge the same price we are charged for
            such fees.
          </div>
          <div class="covid-text">
            Imagine what you could do with all those savings. You could pass the
            discount onto your customers and offer cheaper tickets, essentially
            paying it forward. Or, you can charge the same rates you were before
            and collect the fees on your own. Over a year, this will equal
            thousands of dollars in savings for many applicants.
          </div>
          <div class="covid-text">
            Use our handy calculator to see what your average increase in
            revenue or customer savings would be, and then fill in the
            application form!
          </div>
          <div class="covid-text">
            We understand the delicate position your company is in and we hope
            this CRP is a step in the right direction for you.
          </div>
          <div class="covid-conditions">
            <div>Conditions:</div>
            <div>- Must host events exclusively on INCREDEVENT.</div>
            <div>- Must sign a 2-year exclusivity agreement.</div>
            <div>
              - Accounts with CRP applied are not eligible for our express
              payments for the 12-months you are on the program.
            </div>
            <div>- Certain account specific restrictions may apply.</div>
          </div>
        </div>
        <div class="row covid-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/2BzXlzDtkp8"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="yt"
          ></iframe>
        </div>
        <div class="row">
          <h3>Revenue Calculator</h3>
          <div class="col full calc-wrap">
            <div class="col third rev-calc">
              <div class="col full">
                <diyobo-input
                  type="money"
                  label="Tickets Sold"
                  placeholder="Tickets Sold"
                  :required="true"
                  v-model="tixSold"
                  :val="tixSold"
                />
                <!-- :error="error('nameOfTicketHolder')" -->
              </div>
              <div class="col full">
                <diyobo-input
                  type="money"
                  label="Tickets Sold"
                  placeholder="Tickets Sold"
                  :required="true"
                  v-model="tixSold"
                  :val="tixSold"
                  disabled
                />
                <!-- :error="error('nameOfTicketHolder')" -->
              </div>
            </div>
            <div class="col third rev-calc">
              <div class="col full">
                <diyobo-input
                  type="money"
                  label="Old Fees"
                  placeholder="Old Fees"
                  :required="true"
                  v-model="tixFees"
                  :val="tixFees"
                />
              </div>
              <div class="col full">
                <diyobo-input
                  type="money"
                  label="Our Fees"
                  placeholder="Our Fees"
                  :required="true"
                  v-model="ourFees"
                  :val="'$' + ourFees.toFixed(2)"
                  disabled
                />
              </div>
            </div>
            <div class="col third savings-cont">
              <div class="savings-label">Your Savings</div>
              <div class="savings">${{ (theirCost - ourCost).toFixed(2) }}</div>
              <div class="savings-text">
                Imagine if you saved that much every event!
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <h3>Covid Relief Application</h3>
          <div class="col full covid-form" v-if="!submitted">
            <div class="col half">
              <diyobo-input
                type="text"
                label="Name"
                placeholder="Name"
                :required="true"
                v-model="name"
                :error="error('name')"
                :val="name"
              />
            </div>
            <div class="col half">
              <diyobo-input
                type="text"
                label="Email"
                placeholder="Email"
                :required="true"
                v-model="email"
                :error="error('email')"
                :val="email"
              />
            </div>
            <div class="col half">
              <diyobo-input
                type="text"
                label="Phone"
                placeholder="Phone"
                :required="true"
                v-model="formatPhone"
                :error="error('phone')"
                :val="formatPhone"
                :max="16"
              />
            </div>
            <div class="col half">
              <diyobo-input
                type="text"
                label="Cities"
                placeholder="Cities"
                :required="true"
                v-model="cities"
                :error="error('cities')"
                :val="cities"
              />
            </div>
            <div class="col half">
              <diyobo-input
                type="money"
                label="Current average fees"
                placeholder="Fees"
                :required="true"
                v-model="avgFees"
                :error="error('avgFees')"
                :val="avgFees"
              />
            </div>
            <div class="col half">
              <diyobo-input
                type="money"
                label="Current average revenue"
                placeholder="Revenue"
                :required="true"
                v-model="avgRev"
                :error="error('avgRev')"
                :val="avgRev"
              />
            </div>
            <div class="col full">
              <div class="button-container">
                <diyobo-button
                  type="primary"
                  txt="Submit"
                  v-on:click="submitRequest()"
                />
              </div>
            </div>
          </div>
          <div class="col full submitted" v-else>
            Thank you for your submission.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#app.light > .covid-cont > #Content {
  background-color: #e7e4df;
}
.covid-cont {
  #Content {
    object-fit: cover;
    background-image: var(--covidBackground);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    .content-inner {
      .covid-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .covid-video {
        display: flex;
        margin: 20px auto 0px auto;
        width: 50%;
        height: 330px;
        border-radius: 5px;
        overflow: hidden;
      }

      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .savings-cont {
        .savings-label {
          margin-bottom: 0.5em;
        }

        .savings {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 20px 0px;
          font-size: 50px;
          font-weight: bold;
          background-color: var(--input-background);
          border-radius: 5px;
          color: #44b678;
          box-shadow: var(--input-shadow);
        }

        .savings-text {
          text-align: center;
          margin-top: 0.5em;
        }
      }

      .calc-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .rev-calc {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .text-wrap {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        color: #fff;

        h2 {
          margin: 0px;
          width: 100%;
        }

        .second-header {
          color: var(--text);
          margin-bottom: 40px;
        }
      }

      .covid-text,
      .covid-conditions {
        color: var(--text);
        width: 100%;
        margin-bottom: 20px;
      }

      .covid-conditions {
        font-size: 12px;
      }
    }
  }
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import ModalContent from "@/helpers/modals";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { formatPhoneNumber, emailCheck } from "@/helpers/input-formats.js";

export default {
  name: "comps",
  components: {
    Breadcrumbs,
    DiyoboInput,
    DiyoboButton
  },
  head() {
    return {
      title: "Covid-Relief for Event Planners & Promoters • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "***$0.80 Ticket Fees for 12 months*** INCREDEVENT offers a covid-relief program for event planners and promoters. Calculate your savings here!"
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion"
        },
        {
          name: "author",
          content: "INCREDEVENT"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0"
        }
      ]
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Covid Relief", null]
      ],
      errors: [
        {
          name: "name",
          display: "Name",
          error: false,
          required: true
        },
        {
          name: "phone",
          display: "Phone",
          error: false,
          required: true
        },
        {
          name: "email",
          display: "Email",
          error: false,
          required: true
        },
        {
          name: "cities",
          display: "Cities",
          error: false,
          required: true
        },
        {
          name: "avgFees",
          display: "Current Average Fees",
          error: false,
          required: true
        },
        {
          name: "avgRev",
          display: "Current Average Revenue",
          error: false,
          required: true
        }
      ],
      name: "",
      email: "",
      phone: "",
      cities: "",
      avgFees: "",
      avgRev: "",
      tixSold: null,
      tixFees: null,
      ourFees: 1.0,
      submitted: false
    };
  },
  methods: {
    submitRequest() {
      if (this.validate()) {
        let application = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          cities: this.cities,
          avgFees: this.avgFees,
          avgRev: this.avgRev
        };

        this.$axios
          .post(`/planners/covid-application`, application)
          .then(response => {
            console.log(response.data);

            this.$store.state.bus.$emit("alert", ModalContent.covidApplication);
            this.submitted = true;
          });
      }
    },
    error(input) {
      let error = this.errors.find(e => e.name === input);
      return error && error.error;
    },
    validate() {
      let self = this;

      this.errors.forEach(ele => {
        if (ele.required) {
          if (!this[ele.name]) {
            ele.error = true;
          } else {
            ele.error = false;
          }
          if (ele.name == "phone") {
            if (this[ele.name].length != 16) {
              ele.error = true;
            } else {
              ele.error = false;
            }
          }
          if (ele.name == "email") {
            if (emailCheck(this[ele.name])) {
              ele.error = false;
            } else {
              ele.error = true;
            }
          }
        }
      });

      return !this.errors.some(e => e.error);
    }
  },
  created() {},
  computed: {
    formatPhone: {
      get: function() {
        return this.phone;
      },
      set: function(val) {
        console.log(val);
        this.phone = formatPhoneNumber(val);
      }
    },
    theirCost() {
      return this.tixSold * this.tixFees;
    },
    ourCost() {
      return this.tixSold * this.ourFees;
    },
    theme() {
      return this.$store.state.theme;
    }
  },
  created() {
    this.$store.commit("setTitle", "Covid Relief");
  }
};
</script>
