<template>
  <div class="radio-group" :class="{ error: error }">
    <div class="label">
      {{ label }}
      <b v-if="required">*</b>
    </div>
    <div class="button-wrapper">
      <div class="button" v-for="button in buttons" :key="button.id">
        <label class="container">
          <span class="button-label">
            {{ button.label }}
          </span>
          <input
            type="radio"
            :id="button.id"
            :name="button.name"
            :checked="button.checked"
            v-on:click="onClick(button)"
            :value="checkedName"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.button-label {
  margin-left: 8px;
}

.radio-group {
  margin-bottom: 1.5em;
  .label {
    margin-bottom: 0.5em;
    //
    // font-size: 0.98em;

    b {
      padding-left: 2px;
      color: @colors[input-required];
    }
  }

  &.error {
    label {
      color: #e60022;
    }
    input[type="radio"] {
      background-color: #1a0306;

      &.focus {
        background-color: #47060f;
      }
    }
  }
}

.button-wrapper {
  display: flex;

  .container {
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark {
        background: linear-gradient(#4cac55, #39793f);
      }
    }
  }
  // end container

  .checkmark {
    position: absolute;
    top: 10;
    left: 0;
    width: 22px;
    height: 22px;
    font-size: 1em;
    background-color: var(--checkbox);
    border: 0.2em solid #0f0f0f;
    border-radius: 5px;
    outline-color: var(--input-border);
    margin-right: 16px;
    vertical-align: bottom;
    box-shadow: none;

    &:hover {
      background-color: #1f1f1f;
    }
  }
  // end checkmark
}
</style>
<script>
export default {
  name: "radio-group",
  props: {
    buttons: Array,
    required: Boolean,
    label: String,
    checked: Boolean,
    error: Boolean
  },
  data() {
    return {
      checkedName: ""
    };
  },
  methods: {
    onClick(button) {
      this.$emit("click", button.label);
      this.$emit("change", button.name);
    }
  }
};
</script>
