<template>
  <div class="embed-preview">
    <h3>Preview of Your Embed</h3>
    <div class="preview" :style="{ borderRadius: styles.corner === 'rounded-corners' ? 8 : 0 }">
      <div class="tiers">
        <h2 :style="{ color: colors.text }">Event Name</h2>
        <div class="tier">
          <div class="name">hello</div>
        </div>
      </div>
      <button :style="{ background: colors.buttons, color: colors.text }">yo</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "embed-preview",
  props: {
    styles: Object,
    colors: Object
  }
};
</script>

<style lang="less" scoped>
.embed-preview {
  width: 100%;

  .preview {
    border: 1px solid red;

    h2 {
      text-align: center;
    }
  }
}
</style>