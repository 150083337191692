<template>
  <div id="embeds">
    <view-embed-modal ref="viewEmbedModal" />
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="row">
          <div class="col full">
            <embed-instructions />
          </div>
        </div>
        <div class="row">
          <div class="col full">
            <embed-editor
              :event="url"
              v-on:create="onCreateEmbed"
              v-on:edit="onEditEmbed"
            />
          </div>
        </div>
        <div class="row">
          <div class="col full">
            <div class="embeds-list">
              <h3>Your Embeds</h3>
              <embed-item
                v-for="(embed, index) in embeds"
                :key="index"
                :embed="embed"
                v-on:view-embed="onViewEmbed"
              />
              <div v-if="!embeds.length">No Embeds Found</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import EmbedEditor from "../components/EmbedEditor.vue";
import EmbedItem from "../components/EmbedItem.vue";
import EmbedInstructions from "../components/EmbedInstructions.vue";
import ViewEmbedModal from "../components/modals/ViewEmbedModal.vue";

export default {
  name: "embeds",
  components: {
    Breadcrumbs,
    EmbedEditor,
    EmbedItem,
    EmbedInstructions,
    ViewEmbedModal
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Embed Management"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Embed Tiers on the Web", null]
      ],
      title: "",
      embeds: []
    };
  },
  methods: {
    onCreateEmbed(embed) {
      this.embeds.push(embed);
    },
    onEditEmbed(embed) {
      const index = this.embeds.findIndex(e => e._id === embed._id);
      this.$set(this.embeds, index, embed);
    },
    onViewEmbed(embed) {
      this.$refs.viewEmbedModal.open(embed);
    }
  },
  created() {
    this.$store.commit("setTitle", "Embed Tiers on the Web");
    this.$axios
      .post("/embeds/dashboard", {
        event: this.url
      })
      .then(response => {
        const data = response.data;

        this.title = data.title;
        this.embeds = data.embeds;
      });
  }
};
</script>

<style lang="less" scoped></style>
